/* 1. CSS Variables for easy customization */
:root {
  --primary-color: #4CAF50; /* Green */
  --secondary-color: #FF5722; /* Orange */
  --error-color: #D32F2F; /* Red */
  --background-color: #F7F7F7; /* Light gray */
  --text-color: #333; /* Dark text */
  --gray-color: #777; /* Light gray for secondary text */
  --font-family: 'Arial', sans-serif;
  --font-size-large: 24px;
  --font-size-medium: 18px;
  --font-size-small: 14px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --border-radius: 5px;
}

/* 2. General Reset and Body Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

h1, p {
  margin: 0;
}

/* 3. App Container */
.app-container {
  max-width: 1200px;
  width: 100%;
  padding: var(--spacing-medium);
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.app-title {
  font-size: var(--font-size-large);
  color: var(--primary-color);
  margin-bottom: var(--spacing-small);
}

.app-description {
  font-size: var(--font-size-medium);
  color: var(--gray-color);
  margin-bottom: var(--spacing-large);
  line-height: 1.5;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* 4. Search Input Field */
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-medium);
}

.search-input {
  width: 100%;
  max-width: 300px;
  padding: var(--spacing-small);
  font-size: var(--font-size-medium);
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  outline: none;
  transition: border-color 0.3s ease;
  margin-right: var(--spacing-small); /* Add space between input and button */
}

.search-input:focus {
  border-color: var(--primary-color);
}

/* 5. Search Button */
.search-button {
  padding: var(--spacing-small) var(--spacing-medium);
  font-size: var(--font-size-medium);
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: var(--secondary-color);
}

.search-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* 6. Jargon List */
.jargon-list-container {
  width: 100%;
}

.jargon-list {
  list-style-type: none;
  padding: 0;
}

.jargon-term {
  padding: var(--spacing-small);
  border-bottom: 1px solid #ddd;
  text-align: left;
  transition: background-color 0.3s ease;
}

.jargon-term:hover {
  background-color: #f1f1f1;
}

.term-title {
  font-size: var(--font-size-medium);
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: var(--spacing-small);
}

.term-definition {
  font-size: var(--font-size-small);
  color: var(--gray-color);
}

/* 7. No Results Message */
.no-results {
  font-size: var(--font-size-medium);
  color: var(--error-color);
  font-weight: bold;
  margin-top: var(--spacing-large);
}

/* 8. Responsive Design */
@media (max-width: 768px) {
  .app-container {
    padding: var(--spacing-small);
  }

  .app-title {
    font-size: 22px;
  }

  .app-description {
    font-size: var(--font-size-small);
    margin-bottom: var(--spacing-medium);
  }

  .search-input {
    max-width: 100%;
  }

  .jargon-term {
    padding: var(--spacing-small);
  }

  .term-title {
    font-size: 18px;
  }

  .term-definition {
    font-size: 13px;
  }
}
